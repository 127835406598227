import React, { useContext, useState, useRef, useEffect, useMemo, useCallback } from "react";
import styled from "styled-components";
import gsap from "gsap";
import loadable from '@loadable/component'

import { GatsbyImage } from "gatsby-plugin-image";
import { ScreenContext } from "@components/Layout";

import useMedia from "@hooks/useMedia";

import media from "@styles/media";
import colors from "@styles/colors";
import text from "@styles/text";

const PrimaryButton = loadable(() => import("@components/PrimaryButton"));
const TextAnimation = loadable(() => import("@components/TextAnimation"));

type Props = {
  data: any;
};

const ReadMore: React.FC<Props> = ({ data }) => {
  const [triggerTitle, setTriggerTitle] = useState(false);

  const wrapperRef = useRef(null);
  const hrRef = useRef(null);
  const tl2Start = useMedia("top-=30% top", "top-=30% top", "top-=200% top", "top-=90% top");

  const screen = useContext(ScreenContext);

  useEffect(() => {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: wrapperRef.current,
          start: tl2Start
        },
      });
      tl.to(hrRef.current, {
        width: "100%",
      });
      tl.call(setTriggerTitle, [true], 0.1);


      tl.to(
        ".featured_story0",
        {
          duration: 1.5,
          opacity: 1,
        },
        0.2
      );
      tl.to(
        ".featured_story1",
        {
          duration: 1.5,
          opacity: 1,
        },
        0.4
      );
      tl.to(
        ".featured_story2",
        {
          duration: 1.5,
          opacity: 1,
        },
        0.6
      );

      return () => {
        tl.kill();
      };

  }, []);

  let showCards = useMemo(() => data.allContentfulBlogPost.nodes
    .filter((node) => {
      return node.featured;
    })
    .slice(0, 3)
    .sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    }), [data])

  const getDate = useCallback((inputDate) => {
    const newDate = inputDate.split("-");

    const myNewDate = new Date(
      Date.UTC(newDate[0], newDate[1] - 1, newDate[2])
    );
    const printedDate = new Intl.DateTimeFormat("en-US", {
      dateStyle: "long",
    }).format(myNewDate);

    return printedDate;
  }, [])

  const cardData = useMemo(() => showCards.map((article, i) => {
    const newData = {
      date: getDate(article.date),
      cardImage: article.mainBlogImage.gatsbyImageData,
      cardTitle: article.title,
      description: article.description,
      link: article.title
        .split(" ")
        .filter((e) => e !== "/")
        .join(" ")
        .toLowerCase()
        .replace(/\s+/g, "-"),
    };
    return newData;
  }), [showCards])

  const allFeatured = useMemo(() => cardData.map((story, i) => {
    return (
      <Story
        className={`featured_story${i}`}
        key={`featured_story${i}`}
        href={`/blog/${story.link}`}
      >
        <GatsbyImage
          image={story.cardImage}
          alt="article image"

        />
        <DateText>{story.date}</DateText>
        <SmallTitle>{story.cardTitle}</SmallTitle>
        <Text>{story.description}</Text>
        <PrimaryButton
          text="Read Article"
          textColor={colors.culturedWhite}
          hoverColor={colors.black}
          backgroundColor={colors.black}
          padding
        />
      </Story>
    );
  }), [cardData])

  return (
    <Wrapper ref={wrapperRef}>
      <HR ref={hrRef} />
      <TopSection>
        <Title>
          <TextAnimation
            textArray={[() => "Read more about", () => "what we are doing"]}
            className="readmore-title"
            height={useMedia("5.556vw", "5.556vw", "5.755vw", "7.467vw")}
            trigger={triggerTitle}
          />
        </Title>
        <a href="/blog">
          <PrimaryButton
            text="See More Articles"
            textColor={colors.culturedWhite}
            hoverColor={colors.black}
            backgroundColor={colors.black}
            padding
          />
        </a>
      </TopSection>

      {!screen.mobile ? (
        <TileContainer>{allFeatured}</TileContainer>
      ) : (
        <TileContainer>
          <MobileWrapper>{allFeatured}</MobileWrapper>
        </TileContainer>
      )}
    </Wrapper>
  );
};

export default ReadMore;

const Wrapper = styled.section`
  background-color: ${colors.black};
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  ${media.fullWidth} {
    padding-top: 9.653vw;
    padding-bottom: 7.083vw;
    padding-left: 11.319vw;
    padding-right: 11.319vw;
  }

  ${media.desktop} {
    padding-top: 9.653vw;
    padding-bottom: 7.083vw;
    padding-left: 11.319vw;
    padding-right: 11.319vw;
  }

  ${media.tablet} {
    padding-top: 14.628vw;
    padding-left: 5.995vw;
    padding-right: 5.995vw;
    padding-bottom: 14.388vw;
  }

  ${media.mobile} {
    padding-top: 11.2vw;
    padding-left: 6.667vw;
    padding-right: 6.667vw;
    padding-bottom: 21.333vw;
  }
`;

const DateText = styled.p`
  color: ${colors.jetBlack20};

  ${media.fullWidth} {
    ${text.desktopBodyCopy1};
    margin-top: 3.2vw;
    margin-bottom: 1.4vw;
  }

  ${media.desktop} {
    ${text.desktopBodyCopy1};
    margin-top: 3.2vw;
    margin-bottom: 1.4vw;
  }

  ${media.tablet} {
    ${text.tabletSmallBody}
    margin-top: 3.597vw;
    margin-bottom: 1.559vw;
  }

  ${media.mobile} {
    ${text.mobileSmallBody}
    margin-top: 8vw;
    margin-bottom: 3.467vw;
  }
`;

const Text = styled.p`
  position: relative;
  color: ${colors.culturedWhite60};
  letter-spacing: -0.02em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-bottom: 0.2vw;

  ${text.desktopSmallBody};
  height: 5.5vw;
  width: 17.2vw;
  margin-bottom: 1.667vw;

  ${media.tablet} {
    ${text.tabletSmallBody}
    height: 10.671vw;
    width: 23.621vw;
    margin-bottom: 4.796vw;
    font-size: 1.439vw;
  }

  ${media.mobile} {
    ${text.mobileSmallBody}
    height: 33.333vw;
    width: 100%;
    margin-bottom: 8.8vw;
  }
`;

const SmallTitle = styled.h3`
  color: ${colors.culturedWhite60};
  letter-spacing: -0.02em;
  ${text.desktopPetiteHeading};
  height: 5.2vw;
  margin-bottom: 1.4vw;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  padding-bottom: 0.2vw;

  ${media.tablet} {
    ${text.tabletBodyCopy2}
    height: 5.6vw;
    margin-bottom: 2.4vw;
    line-height: 100%;
  }

  ${media.mobile} {
    ${text.mobileBodyCopy2}
    height: 13.5vw;
    margin-bottom: 5.3vw;
    line-height: 100%;
  }
`;

const Story = styled.a`
  overflow: hidden;
  position: relative;
  opacity: 0;
  text-decoration: none;

  height: 40.8vw;
  width: 22.6vw;

  .gatsby-image-wrapper {
    width: 100%;
    height: 17.4vw;
    object-fit: cover;
  }

  ${media.tablet} {
    height: auto;
    width: 25.659vw;

    .gatsby-image-wrapper {
      width: 100%;
      height: 19.784vw;
    }
  }

  ${media.mobile} {
    width: 52.533vw;
    margin-right: 12vw;
    height: auto;
    opacity: 1;
    .gatsby-image-wrapper {
      width: 100%;
      height: 44vw;
    }
  }
`;

const TileContainer = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.mobile} {
    justify-content: flex-start;
    position: relative;
    width: 100vw;
    overflow: scroll;
    padding-bottom: 10vw;
  }
`;

const MobileWrapper = styled.div`
  ${media.tablet} {
  }
  ${media.mobile} {
    display: flex;
    justify-content: flex-start;
    position: relative;
    width: 208vw;
  }
  ${media.fullWidth} {
  }
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
  width: 100%;
  a {
    text-decoration: none;
  }
  ${media.fullWidth} {
    padding-top: 2.8vw;
    margin-bottom: 5.6vw;
  }

  ${media.desktop} {
    padding-top: 2.8vw;
    margin-bottom: 5.6vw;
  }

  ${media.tablet} {
    padding-top: 4.796vw;
    margin-bottom: 9.233vw;
  }

  ${media.mobile} {
    flex-direction: column;
    margin-bottom: 21.333vw;
  }
`;

const Title = styled.h2`
  color: ${colors.culturedWhite100};
  display: flex;
  flex-direction: column;

  ${media.fullWidth} {
    ${text.desktopXLHeading};
    width: 47.4vw;
  }

  ${media.desktop} {
    ${text.desktopXLHeading};
    width: 47.4vw;
  }

  ${media.tablet} {
    ${text.tabletSHeading};
    width: 46.643vw;
  }

  ${media.mobile} {
    ${text.mobileXXSHeading}
    width: 66.667vw;
    margin-bottom: 5.333vw;
  }
`;

const HR = styled.hr`
  width: 40%;
  height: 1px;
  background: ${colors.culturedWhite60};
  margin: 0px;
  border: none;

  ${media.mobile} {
    margin-bottom: 8vw;
  }
`;
